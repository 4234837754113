import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserAutoCompleteFilterByFields } from 'src/app/enums/user-auto-complete-target-fields';
import { WorkerProfileViewModel } from 'src/app/models/userProfile/worker-profile-view-model';
import { WorkerProfileService } from 'src/app/services/worker-profile.service';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DatetimePickerMode } from "../../../enums/datetime-picker-mode.enum";
import { Gender } from "../../../enums/gender.enum";
import { CheckedVisitorType } from "../../../models/visitor-type";
import { EmergencyContactEmitResponse } from "../../../models/emergency-contact-emit-response";
import * as moment from "moment/moment";
import { dateFormat } from "../../../helpers/date-time-format";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { UserService } from 'src/app/services/user.service';
import { UserPermission } from 'src/app/enums/user-permission.enum';

@Component({
  selector: 'obc-worker-profile',
  templateUrl: './worker-profile.component.html',
  styleUrls: ['./worker-profile.component.scss']
})
export class WorkerProfileComponent implements OnInit {
  UserAutoCompleteFilterByFields = UserAutoCompleteFilterByFields;
  isInProcess: boolean = false;
  showPanels: boolean = true;
  hasAccessToEdit: boolean = false;
  _profile: WorkerProfileViewModel;
  infoFormGroupSubscription: Subscription;
  get profile() {
    return this._profile;
  }

  _siteId: number;
  get siteId() {
    return this._siteId;
  }

  @Input() set siteId(sid: number) {
    this._siteId = sid;
    this.reloadAll();
  }

  get selectedSiteArray() {
    return this.siteId ? [this.siteId] : [];
  }

  @Input() set profile(data: WorkerProfileViewModel) {
    this._profile = data;
    this.isEditMode = false;
    this.isEditFormDirty.next(false);

    this.setProfileDataToControls();
  }

  @Output() isEditFormDirty: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onProfileUpdated: EventEmitter<void> = new EventEmitter<void>();

  reloadAll() {
    this.calculateEditAccess();
    this.isInProcess = true;
    this.showTab = [];
    this.showPanels = false;
    setTimeout(() => {
      this.showTab = [1];
      this.showPanels = true;
      this.isInProcess = false;
    }, 10);
  }

  showEditButton() {
    return !this.isEditMode && this.hasAccessToEdit;
  }

  setProfileDataToControls() {
    this.infoFormGroup = new FormGroup({});
    this.infoFormGroup.addControl('firstName', new FormControl(this.profile?.firstName))
    this.infoFormGroup.addControl('lastName', new FormControl(this.profile?.lastName))
    this.infoFormGroup.addControl('companySupplierTitle', new FormControl(this.profile?.companySupplierTitle))
    this.infoFormGroup.addControl('companySupplier', new FormControl(this.profile?.companySupplierId))
    this.infoFormGroup.addControl('visitorType', new FormControl(this.profile?.visitorTypeId ?? null))
    this.infoFormGroup.addControl('visitorTypeTitle', new FormControl(this.profile?.visitorTypeTitle ?? null))
    this.infoFormGroup.addControl('email', new FormControl(this.profile?.email, [Validators.email]))
    this.infoFormGroup.addControl('gender', new FormControl(this.profile?.gender))
    this.infoFormGroup.addControl('dateOfBirth', new FormControl(
      this.profile?.dateOfBirth ? new Date(this.profile?.dateOfBirth) : null)
    )
    this.infoFormGroup.addControl('emergencyContact', new FormControl(this.profile?.emergencyContact));

    this.calculateEditAccess();
  }

  showTab = [1];

  constructor(
    private workerProfileService: WorkerProfileService,
    private toast: ToastrService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.calculateEditAccess();
  }

  private calculateEditAccess() {
    this.hasAccessToEdit = this.userService.hasAccessToEditUserDirectory(this.siteId);
  }

  isSetGender() {
    return this.profile.gender !== undefined && this.profile.gender !== null;
  }

  isSetDOB() {
    return this.profile.dateOfBirth !== undefined
      && this.profile.dateOfBirth !== null
      && this.profile.dateOfBirth.toString() !== "Invalid Date";
  }

  getProfileAvatarText() {
    return this.profile?.firstName?.charAt(0).toUpperCase() +
      this.profile?.lastName?.charAt(0).toUpperCase();
  }

  infoFormGroup: FormGroup = new FormGroup({});

  isEditMode: boolean = false;
  initialEditFormGroupSnapshot: string;
  updatedEditFormGroupSnapshot: string;
  onEditProfileButtonClicked() {
    this.isEditMode = !this.isEditMode;
    if (this.isEditMode) {
      this.infoFormGroupSubscription?.unsubscribe();
      this.setProfileDataToControls();
      this.infoFormGroupSubscription = this.infoFormGroup.valueChanges.subscribe((res) => {
        this.getSnapshotOfFormGroupData(res);
      });
    }
  }

  getSnapshotOfFormGroupData(data: [key: string]) {
    let stringifiedKeyValueList = [];
    for (const [key, value] of Object.entries(data)) {
      let entryValue: any = value;
      if (key == 'gender') {
        entryValue = this.getGenderValue();
      } else if (key == 'dateOfBirth') {
        entryValue = moment.utc(entryValue).local().format("YYYY-MM-DD");
      }
      stringifiedKeyValueList.push(`${key}:${JSON.stringify(entryValue)}`);
    }
    if (!this.initialEditFormGroupSnapshot) {
      this.initialEditFormGroupSnapshot = stringifiedKeyValueList.join("");
    } else {
      this.updatedEditFormGroupSnapshot = stringifiedKeyValueList.join("");

      this.isEditFormDirty.next(this.isInfoFormGroupUpdated());
    }
  }

  isInfoFormGroupUpdated(): boolean {
    return this.initialEditFormGroupSnapshot != this.updatedEditFormGroupSnapshot;
  }

  isSaveButtonDisabled() {
    return this.infoFormGroup.invalid || !this.isInfoFormGroupUpdated();
  }

  onSaveProfileButton() {
    this.isInProcess = true;
    const reqModel = {
      dateOfBirth: this.infoFormGroup.controls.dateOfBirth.value ? moment(this.infoFormGroup.controls.dateOfBirth.value, dateFormat).utcOffset(0, true).toDate() : null,
      email: this.infoFormGroup.controls.email.value,
      emergencyContact: this.infoFormGroup.controls.emergencyContact.value,
      firstName: this.infoFormGroup.controls.firstName.value,
      lastName: this.infoFormGroup.controls.lastName.value,
      companyId: this.profile.companyId,
      siteId: this.siteId,
      userId: this.profile.userId,
      gender: this.getGenderValue(),
      companySupplierId: this.infoFormGroup.controls.companySupplier.value,
      visitorTypeId: this.infoFormGroup.controls.visitorType.value ?
        +this.infoFormGroup.controls.visitorType.value : null,
    };
    this.workerProfileService.updateUserProfile(reqModel).subscribe({
      next: (newProfile) => {
        this.toast.success(`Profile was updated successfully`);
        this.profile = newProfile;
        this.onProfileUpdated.emit();
        this.initialEditFormGroupSnapshot = null;
        this.isEditMode = false;
        this.isInProcess = false;
        this.isEditFormDirty.next(false);
      },
      error: (_) => {
        this.toast.error(`Something went wrong!`);
        this.isInProcess = false;
      },
    })
  }

  getGenderValue() {
    let genderControl = this.infoFormGroup.controls.gender;
    if (genderControl?.value == null || genderControl?.value === "null") {
      return null;
    }
    return +genderControl?.value;
  }

  selectVisitorType(data: CheckedVisitorType) {
    this.infoFormGroup.controls.visitorType.setValue(data?.id)
    this.infoFormGroup.controls.visitorTypeTitle.setValue(data?.title)
  }

  updateEmergencyContact(data: EmergencyContactEmitResponse) {
    if (data?.value && data?.isValid) {
      this.infoFormGroup?.controls.emergencyContact.setValue(JSON.stringify(data.value));
    }
    if (data?.isValid) {
      this.infoFormGroup?.controls.emergencyContact.setErrors(null);
    } else {
      this.infoFormGroup?.controls.emergencyContact.setErrors({ 'incorrect': true });
    }
    this.infoFormGroup?.updateValueAndValidity();
  }

  onSelectSupplier(data) {
    this.infoFormGroup.controls.companySupplierTitle.setValue(data?.name);
  }

  DatetimePickerMode = DatetimePickerMode;
  Gender = Gender;
}
